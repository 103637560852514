import * as React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFaceSadCry } from "@fortawesome/free-solid-svg-icons";

import Layout from "../components/Layout";

const NotFoundPage = () => (
  <Layout>
    <div className="h-full flex flex-col justify-center items-center">
      <FontAwesomeIcon icon={faFaceSadCry} size="4x" className="mb-4" />
      <h1 className="mb-4">Page Not Found</h1>
      <span>Oops...the page you're looking for could not be found.</span>
    </div>
  </Layout>
);

export default NotFoundPage;
